import ReactPortal from 'components/ReactPortal';

const CookieManager = () => (
  <ReactPortal portalQuerySelector="body">
    <div id="cookie__popup__settings" className="cookie__popup__settings d-flex justify-content-center align-items-center" role="dialog" aria-labelledby="cookie__popup__settings__head__headline" aria-describedby="cookie__popup__settings__holder" aria-modal="true">
        <div className="cookie__popup__settings__content">
            <div className="cookie__popup__settings__content__head" id="cookie__popup__settings__content__holder">
                <h2 className="cookie__popup__settings__content__head__headline"><span data-nosnippet="false">Cookie settings</span></h2>
                <p className="cookie__popup__settings__content__head__description">
                    <span data-nosnippet="false">
                        We use cookies to improve, customise and analyse the experience of our website. You choose whether you want to consent to us placing cookies for these purposes and you can withdraw your consent at any time. Choose your settings and save them, or read more <a href="/about-fagerhult/Term-of-use/about-cookies/">about cookies</a>.
                    </span>
                </p>
            </div>
            <div className="cookie__popup__settings__content__body">
                <div className="cookie__popup__settings__content__body__controls">
                    <div className="cookie__popup__settings__content__body__controls__panel">
                        <button aria-expanded="false" className="cookie__popup__settings__content__body__controls__panel__button" aria-controls="cookie__popup__settings__content__body__controls__panel__description">
                            <h3 className="cookie__popup__settings__content__body__controls__panel__headline">
                                <span className="cookie__popup__settings__content__body__controls__panel__headline__title" data-nosnippet="false">
                                    Necessary cookies
                                </span>
                                <label className="switch" title="Cannot be disabled" htmlFor="mandatory__cookie__category">
                                    <input type="checkbox" id="mandatory__cookie__category" />
                                    <span className="slider round" id="mandatory"></span>
                                </label>                                
                                <i className="cookie__popup__settings__content__body__controls__panel__arrow far fa-angle-down"></i>
                            </h3>
                            <p className="cookie__popup__settings__content__body__controls__panel__description">
                                <span data-nosnippet="false">
                                    Helps our website to properly function for you.
                                </span>
                            </p>
                        </button>                        
                    </div>
                    <div className="cookie__popup__settings__content__body__controls__panel">
                        <button aria-expanded="false" className="cookie__popup__settings__content__body__controls__panel__button" aria-controls="cookie__popup__settings__content__body__controls__panel__description">
                            <h3 className="cookie__popup__settings__content__body__controls__panel__headline">
                                <span className="cookie__popup__settings__content__body__controls__panel__headline__title" data-nosnippet="false">
                                    Functional cookies
                                </span>
                                <label className="switch" htmlFor="functional__cookie__category">
                                    <input type="checkbox" id="functional__cookie__category"/>
                                    <span className="slider round" id="functional"></span>
                                </label>
                                <i className="cookie__popup__settings__content__body__controls__panel__arrow far fa-angle-down"></i>
                            </h3>
                            <p className="cookie__popup__settings__content__body__controls__panel__description">
                                <span data-nosnippet="false">
                                    Used to give you a good experience, such as remembering which documents you have in a document collection.
                                </span>
                            </p>
                        </button>
                    </div>
                    <div className="cookie__popup__settings__content__body__controls__panel">
                        <button aria-expanded="false" className="cookie__popup__settings__content__body__controls__panel__button" aria-controls="cookie__popup__settings__content__body__controls__panel__description">
                            <h3 className="cookie__popup__settings__content__body__controls__panel__headline">
                                <span className="cookie__popup__settings__content__body__controls__panel__headline__title" data-nosnippet="false">
                                    Analytical cookies
                                </span>
                                <label className="switch" htmlFor="analytical__cookie__category">
                                    <input type="checkbox" id="analytical__cookie__category" />
                                    <span className="slider round" id="analytical"></span>
                                </label>
                                <i className="cookie__popup__settings__content__body__controls__panel__arrow far fa-angle-down"></i>
                            </h3>
                            <p className="cookie__popup__settings__content__body__controls__panel__description">
                                <span data-nosnippet="false">
                                    Helps us understand how our visitors interact with the website, so that we can analyze and improve it.
                                </span>
                            </p>
                        </button>
                    </div>
                    <div className="cookie__popup__settings__content__body__controls__panel">
                        <button aria-expanded="false" className="cookie__popup__settings__content__body__controls__panel__button" aria-controls="cookie__popup__settings__content__body__controls__panel__description">
                            <h3 className="cookie__popup__settings__content__body__controls__panel__headline">
                                <span className="cookie__popup__settings__content__body__controls__panel__headline__title" data-nosnippet="false">
                                    Marketing cookies
                                </span>
                                <label className="switch" htmlFor="marketing__cookie__category">
                                    <input type="checkbox" id="marketing__cookie__category"/>
                                    <span className="slider round" id="marketing"></span>
                                </label>
                                <i className="cookie__popup__settings__content__body__controls__panel__arrow far fa-angle-down"></i>
                            </h3>
                            <p className="cookie__popup__settings__content__body__controls__panel__description">
                                <span data-nosnippet="false">
                                    Helps us understand your activity and movement between websites, so we can tailor content and advertising to your interests.
                                </span>
                            </p>
                        </button>
                    </div>
                </div>
            </div>
            <div className="cookie__popup__settings__content__footer">
                <button className="btn btn-secondary btn-sm-new cookie__popup__settings__content__footer__button save-cookie-settings--btn">
                    <span data-nosnippet="false">
                        Save settings
                    </span>
                </button>
                <button className="btn btn-primary btn-sm-new cookie__popup__settings__content__footer__button accept-all-cookie-groups--btn">
                    <span data-nosnippet="false">
                        Accept all cookies
                    </span>
                </button>
            </div>
        </div>
    </div>
  </ReactPortal>
);

export default CookieManager;
