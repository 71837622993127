import { AppState } from './store';
import { createSlice } from '@reduxjs/toolkit';
import { General } from 'type';
import { HYDRATE } from 'next-redux-wrapper';

// Type for our state
export type GeneralState = {
  general: General | null;
};

// Actual Slice
export const generalSlice = createSlice({
  name: 'general',
  initialState: {} as any,

  reducers: {
    setGeneral(state, action) {
      state.general = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase<any>(HYDRATE, (state, action) => ({
      ...state,
      ...action.payload,
    }));
  },
});

export const { setGeneral } = generalSlice.actions;

export const selectGeneralState = (state: AppState): General =>
  state.general.general;

export default generalSlice.reducer;
