import CookieManager from 'compositions/CookieManager';
import Script from 'next/script';
import { Provider } from 'react-redux';
import { wrapper } from 'store/store';
import '../../styles/globals.css';
import '../../styles/cookie-popup-cleaned.css';
import type { AppProps } from 'next/app';
import { useGoogleTagManager } from '@grace-studio/graceful-next/hooks';

const MyApp = ({ Component, ...rest }: AppProps) => {
  useGoogleTagManager('GTM-KVVBG6V');
  const { store, props } = wrapper.useWrappedStore(rest);

  return (
    <>
      <Script
        type="text/javascript"
        src="//script.crazyegg.com/pages/scripts/0038/0360.js"
      />
      <Provider store={store}>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,viewport-fit=cover"
        />
        <Component {...props.pageProps} />
      </Provider>

      <CookieManager />

      <Script src="cookiepopup/jquery.cookie.min.js" type="text/javascript" />
      <Script
        src="cookiepopup/cookiePopup.js"
        type="text/javascript"
        strategy="lazyOnload"
      />
    </>
  );
};

export default MyApp;
