import { AppState } from './store';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import {
  CLEValues,
  ControlType,
  LightingSolution,
  Room,
  RoomOptions,
} from 'type';

// Type for our state
export type RoomState = {
  room: Room | null;
  selectedRoomOptions: RoomOptions | null;
};

// Actual Slice
export const roomSlice = createSlice({
  name: 'room',
  initialState: {} as any,

  reducers: {
    setRoom(state, action) {
      state.room = action.payload;
    },

    setSelectedRoomOptions(state, action) {
      state.selectedRoomOptions = action.payload;
    },

    setLightingSolution(state, action) {
      const hasControlType = !!action.payload?.cleValues?.find(
        (values: CLEValues) =>
          values.controlType === state?.selectedRoomOptions?.controlType
      );

      state.selectedRoomOptions = {
        ...state.selectedRoomOptions,
        lightingSolution: action.payload,
        controlType: hasControlType
          ? state.selectedRoomOptions.controlType
          : 'manual',
      };
    },

    setAdjustments(state, action) {
      state.selectedRoomOptions = {
        ...state.selectedRoomOptions,
        adjustments: {
          ...state.selectedRoomOptions?.adjustments,
          [action.payload.category]: action.payload,
        },
      };
    },

    setControlType(state, action) {
      state.selectedRoomOptions = {
        ...state.selectedRoomOptions,
        controlType: action.payload.slug.current,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase<any>(HYDRATE, (state, action) => ({
      ...state,
      ...action.payload,
    }));
  },
});

export const {
  setRoom,
  setLightingSolution,
  setAdjustments,
  setControlType,
  setSelectedRoomOptions,
} = roomSlice.actions;

export const selectRoomState = (state: AppState): Room => state.room.room;
export const selectSelectedRoomOptions = (state: AppState): RoomOptions =>
  state.room.selectedRoomOptions;
export const selectLightingSolutionState = (
  state: AppState
): LightingSolution => state.room.selectedRoomOptions?.lightingSolution;
export const selectAdjustments = (
  state: AppState
): RoomOptions['adjustments'] => state.room.selectedRoomOptions?.adjustments;
export const selectControlType = (state: AppState): ControlType =>
  state.room.selectedRoomOptions?.controlType;

export default roomSlice.reducer;
